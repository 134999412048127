import React from 'react';
import {Link} from 'gatsby';

import navigation from '../data/_navigation';

const Navigation = ({lang, path}) => (
    <div className="navigation">
        <ul className="navigation-list">
            {navigation.map((item, i) => (
                <li key={i} className={`navigation-list-item ${item.path[lang] === path && 'enabled'}`}>
                    {item.doc ? (
                        <a href={item.path[lang]}>{item.label[lang]}</a>
                    ) : (
                        <Link to={item.path[lang]}>{item.label[lang]}</Link>
                    )}
                </li>
            ))}
        </ul>
    </div>
);

export default Navigation;
