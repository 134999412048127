import React from 'react';
import Header from '../components/Header';
import Meta from '../components/Meta';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const Layout = ({description, keywords, lang, name, path, title, url, children}) => (
    <div className="super-container">
        <Meta
            title={title}
            name={name}
            description={description}
            keywords={keywords}
            url={url}
            path={path}
            lang={lang}
        />
        <div className="container">
            <Header lang={lang} path={path || '/en/about'} />
            <div className="main">
                <div className="content">
                    {children}
                </div>
                <Navigation lang={lang} path={path} />
            </div>
            <Footer />
        </div>
    </div>
);

export default Layout;
