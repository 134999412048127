const dictionary = {
    _linkOpenAccess: {
        en: 'https://en.wikipedia.org/wiki/Open_access',
        de: 'https://de.wikipedia.org/wiki/Open_Access',
        pl: 'https://pl.wikipedia.org/wiki/Otwarty_dostęp',
    },
    _linkCreativeCommons: {
        en: 'https://creativecommons.org/licenses/by-nc-nd/4.0/deed.en',
        de: 'https://creativecommons.org/licenses/by-nc-nd/4.0/deed.de',
        pl: 'https://creativecommons.org/licenses/by-nc-nd/4.0/deed.pl',
    },
    abstracts: {
        en: 'Abstracts',
        de: 'Abstracts',
        pl: 'Abstrakty',
    },
    accepted: {
        en: 'Accepted',
        de: 'Datum der Annahme zum Druck',
        pl: 'Data przyjęcia artykułu do druku',
    },
    archive: {
        en: 'Archive',
        de: 'Archiv',
        pl: 'Archiwum',
    },
    audioFile: {
        en: 'Audio file',
        de: 'Tonbeispiel',
        pl: 'Plik dźwiękowy',
    },
    authorsIndex: {
        en: 'List of authors',
        de: 'Index der Autoren',
        pl: 'Baza Autorów',
    },
    bounceRate: {
        en: 'Bounce Rate',
        de: 'Absprungrate',
        pl: 'Współczynnik odrzuceń',
    },
    contents: {
        en: 'Contents',
        de: 'Inhalt',
        pl: 'Spis treści',
    },
    creativeCommonsInfo: {
        en: 'Texts published in the journal ‘Studia Translatorica’ are available in the Open Access on the basis of the CC BY-NC-ND license.',
        de: 'Texte, die in der Zeitschrift „Studia Translatorica“ veröffentlicht werden, stehen allen Nutzern im Open Access auf Grund der Lizenz CC BY-NC-ND zur Verfügung.',
        pl: 'Teksty publikowane w czasopiśmie „Studia Translatorica” są udostępniane w wolnym dostępie (Open Access) na podstawie licencji CC BY-NC-ND.',
    },
    issue: {
        en: 'Issue',
        de: 'Heft',
        pl: 'Numer',
    },
    keywords: {
        en: 'Keywords',
        de: 'Schlüsselwörter',
        pl: 'Słowa kluczowe',
    },
    keywordsIndex: {
        en: 'Keywords database',
        de: 'Index der Schlüsselwörter',
        pl: 'Baza słów kluczowych',
    },
    noResults: {
        en: 'No results',
        de: 'Keine Ergebnisse',
        pl: 'Brak wyników',
    },
    pp: {
        en: 'pp.',
        de: 'S.',
        pl: 's.',
    },
    publishedOnline: {
        en: 'published online',
        de: 'online zugänglich',
        pl: 'data publikacji online',
    },
    received: {
        en: 'Received',
        de: 'Datum der Einreichung',
        pl: 'Data przesłania artykułu do redakcji',
    },
    searchResultsFor: {
        en: 'Search results for “*”',
        de: 'Suchergebnisse für „*“',
        pl: 'Wyniki wyszukiwania dla „*”',
    },
    searchByAuthorResultsFor: {
        en: 'Search results for “*” (author)',
        de: 'Suchergebnisse für „*“ (Author)',
        pl: 'Wyniki wyszukiwania dla „*” (autor)',
    },
    searchByKeywordResultsFor: {
        en: 'Search results for “*” (keyword)',
        de: 'Suchergebnisse für „*“ (Schlüsselwort)',
        pl: 'Wyniki wyszukiwania dla „*” (słowo kluczowe)',
    },
};

export default dictionary;
