import React from 'react';
import {Link, navigate} from 'gatsby';

import Divider from '../components/Divider';
import locales from '../data/_locales';
import st_logo from '../images/st_logo.png';

class Header extends React.Component {
  handleSubmit = event => {
    event.preventDefault();
    const query = event.currentTarget[0].value;
    navigate(`/${this.props.lang}/search?query=${query}`);
  }

  render() {
    const {lang, path} = this.props;

    return (
        <div>
            <div className="header">
                <div className="header-top">
                    <div className="header-search">
                        <form onSubmit={this.handleSubmit}>
                            <input
                                type="text"
                                name="query"
                                placeholder=""
                                autocomplete="off"
                            />
                            <button type="submit"><i className="icon-search" /></button>
                        </form>
                    </div>
                    <div className="header-navigation">
                        <ul className="header-navigation-list">
                            {locales.map((locale, i) => (
                                <li key={i} className={`header-navigation-list-item ${locale.lang === lang && 'enabled'}`}>
                                    <Link to={path && path.replace(`/${lang}/`, `/${locale.lang}/`)}>{locale.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="header-bottom-left">
                        <Link to={`/${lang}/scope`}>
                            <img  className="st-logo" src={st_logo} alt="st-logo" />
                        </Link>
                    </div>
                    <div className="header-bottom-right">
                        <h1>Studia Translatorica</h1>
                        <p>{`ISSN: 2084-3321 • e-ISSN: 2657-4802 • DOI: 10.23817/strans`}</p>
                    </div>
                </div>
            </div>
            <Divider weak />
        </div>
    )
  }
}

export default Header;
