import React from 'react';

import ztig_logo from '../images/ztig_logo.png';
import publishers from '../data/_publishers';

const Footer = () => (
    <div className="footer">
        <div className="footer-navigation">
            <ul className="footer-navigation-list">
                {publishers.map(({label, path}, i) => (
                    <li key={i} className="footer-navigation-list-item">
                        <a href={path} target="_blank" rel="noopener noreferrer">{label}</a>
                    </li>
                ))}
            </ul>
        </div>
        <div className="footer-right">
            <a
                className="ztig-link"
                href="http://www.ifg.uni.wroc.pl/zaklady/zaklad-translatoryki-i-glottodydaktyki/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img  className="ztig-logo" src={ztig_logo} alt="ztig-logo" />
            </a>
        </div>
    </div>
);

export default Footer;
