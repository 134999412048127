const navigation = [
    {
        name: 'scope',
        path: {
            en: '/en/scope',
            de: '/de/scope',
            pl: '/pl/scope',
        },
        label: {
            en: 'Mission and scope',
            de: 'Themenbereich und Zielsetzung',
            pl: 'Zakres i cel',
        },
    },
    {
        name: 'editorial-board',
        path: {
            en: '/en/editorial-board',
            de: '/de/editorial-board',
            pl: '/pl/editorial-board',
        },
        label: {
            en: 'Editorial board',
            de: 'Redaktionsbeirat',
            pl: 'Rada Redakcyjna',
        },
    },
    {
        name: 'scientific-board',
        path: {
            en: '/en/scientific-board',
            de: '/de/scientific-board',
            pl: '/pl/scientific-board',
        },
        label: {
            en: 'Advisory board',
            de: 'Wissenschaftlicher Beirat',
            pl: 'Rada Naukowa',
        },
    },
    {
        name: 'editorial-staff',
        path: {
            en: '/en/editorial-staff',
            de: '/de/editorial-staff',
            pl: '/pl/editorial-staff',
        },
        label: {
            en: 'Editorial staff',
            de: 'Redaktion',
            pl: 'Redakcja',
        },
    },
    {
        name: 'evaluation',
        path: {
            en: '/en/evaluation',
            de: '/de/evaluation',
            pl: '/pl/evaluation',
        },
        label: {
            en: 'Indexing',
            de: 'Indexierung in Datenbanken',
            pl: 'Indeksacja w bazach danych',
        },
    },
    {
        name: 'reviewers',
        path: {
            en: '/en/reviewers',
            de: '/de/reviewers',
            pl: '/pl/reviewers',
        },
        label: {
            en: 'Reviewers',
            de: 'Gutachter',
            pl: 'Recenzenci',
        },
    },
    {
        name: 'review-process',
        path: {
            en: '/en/review-process',
            de: '/de/review-process',
            pl: '/pl/review-process',
        },
        label: {
            en: 'Reviewing procedure',
            de: 'Begutachtungsverfahren',
            pl: 'Procedura recenzowania',
        },
    },
    {
        name: 'authors',
        path: {
            en: '/en/authors',
            de: '/de/authors',
            pl: '/pl/authors',
        },
        label: {
            en: 'Authors',
            de: 'Autoren',
            pl: 'Autorzy',
        },
    },
    {
        name: 'authors-info',
        path: {
            en: '/en/authors-info',
            de: '/de/authors-info',
            pl: '/pl/authors-info',
        },
        label: {
            en: 'For Authors',
            de: 'Informationen für Autoren',
            pl: 'Dla Autorów',
        },
    },
    {
        name: 'ethics',
        path: {
            en: '/en/ethics',
            de: '/de/ethics',
            pl: '/pl/ethics',
        },
        label: {
            en: 'Ethical standards',
            de: 'Ethische Richtlinien',
            pl: 'Zasady etyczne',
        },
    },
    {
        name: 'keywords',
        path: {
            en: '/en/keywords',
            de: '/de/keywords',
            pl: '/pl/keywords',
        },
        label: {
            en: 'Keywords database',
            de: 'Register der Schlüsserwörter',
            pl: 'Baza słów kluczowych',
        },
    },
    {
        name: 'archive',
        path: {
            en: '/en/archive',
            de: '/de/archive',
            pl: '/pl/archive',
        },
        label: {
            en: 'Archive',
            de: 'Archiv',
            pl: 'Archiwum',
        },
    },
    {
        name: 'call-for-papers',
        path: {
            en: '/en/call-for-papers',
            de: '/de/call-for-papers',
            pl: '/pl/call-for-papers',
        },
        label: {
            en: 'Call for papers',
            de: 'Call for papers',
            pl: 'Call for papers',
        },
    },
    {
        name: 'contact',
        path: {
            en: '/en/contact',
            de: '/de/contact',
            pl: '/pl/contact',
        },
        label: {
            en: 'Contact',
            de: 'Kontakt',
            pl: 'Kontakt',
        },
    },
];

export default navigation;
